'use client';

// Indicate that this component should run on the client side
import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Lexend, Noto_Sans, Baloo_2, Noto_Sans_Gujarati } from 'next/font/google';
import { PRIMARY, SECONDARY, SUCCESS, TEXT_DARK } from '@/constants/colors';
const lexend = Lexend({
  weight: ['300', '400', '500', '700'],
  subsets: ['latin'],
  display: 'swap'
});
const notoSans = Noto_Sans({
  weight: ['400', '500', '600', '700', '900'],
  subsets: ['latin', 'devanagari'],
  display: 'swap'
});
const baloo2 = Baloo_2({
  weight: ['400', '500', '600', '700', '800'],
  subsets: ['latin', 'devanagari'],
  display: 'swap'
});
const notoSansGujarati = Noto_Sans_Gujarati({
  weight: ['400', '500', '600', '700', '800', '900'],
  subsets: ['latin', 'gujarati'],
  display: 'swap'
});

// Function to determine the font based on the language
const getFontFamily = (lang: string) => {
  switch (lang) {
    case 'hi':
      return notoSans.style.fontFamily;
    case 'mr':
      return baloo2.style.fontFamily;
    case 'gu':
      return notoSansGujarati.style.fontFamily;
    default:
      return lexend.style.fontFamily;
  }
};
const ClientThemeProvider = ({
  children,
  locale
}: {
  children: React.ReactNode;
  locale: string;
}) => {
  const theme = React.useMemo(() => createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: PRIMARY
      },
      secondary: {
        main: SECONDARY
      },
      success: {
        main: SUCCESS
      }
    },
    typography: {
      fontFamily: getFontFamily(locale || 'en'),
      allVariants: {
        color: TEXT_DARK
      }
    }
  }), [locale]);
  return <ThemeProvider theme={theme} data-sentry-element="ThemeProvider" data-sentry-component="ClientThemeProvider" data-sentry-source-file="ClientThemeProvider.tsx">
      <CssBaseline data-sentry-element="CssBaseline" data-sentry-source-file="ClientThemeProvider.tsx" />
      {children}
    </ThemeProvider>;
};
export default ClientThemeProvider;